import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import XLayout from '../common/XLayout';
import LoginLogo from '../../assets/images/LoginLogo.png';
import SwitchLogo from '../../assets/images/SwitchToNPCLogo.png';
import TranslateLogo from '../../assets/images/TranslateLogo.png';
import { modalState } from '../../recoil/modalAtom';
import { AuthIntentStateType, authIntentState } from '../../recoil/authIntentAtom';
import { authenticatedState } from '../../recoil/authenticatedAtom';
import { useEffect, useState } from 'react';
import i18n from '../../locale/i18n';
import { Trans, useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { adminAuthenticatedState } from '../../recoil/adminAuthenticatedAtom';
import { hasPersonalInfoState } from '../../recoil/personalInfo';
interface ButtonImageType {
  buttonImage: string;
}

const languages: string[][] = [
  ['en', 'English'],
  ['ko', '한국어'],
  ['ja', '日本語'],
  ['zh', '中国人'],
];
const Hamburger = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [modal, setModal] = useRecoilState(modalState);
  const [authIntent, setAuthIntent] = useRecoilState(authIntentState);
  const [isAuthenticated, setIsAuthenticated] = useRecoilState(authenticatedState);
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useRecoilState(adminAuthenticatedState);
  const [isTranslateOpen, setIsTranslateOpen] = useState<boolean>(false);
  const [personalInfo, setPersonalInfo] = useRecoilState(hasPersonalInfoState);
  const [, , removeCookie] = useCookies(['access_token', 'refresh_token']);
  const handleClickXButton = () => {
    setModal('none');
  };

  useEffect(() => {
    if (!localStorage.getItem('language')) {
      localStorage.setItem('language', 'en');
    }
  }, []);
  const prepareForLogin = (intent: AuthIntentStateType['intent']) => {
    if (intent === 'host') {
      setAuthIntent({
        ...authIntent,
        intent: intent,
        previousPath: '/main',
        intentPath: '/host/manage-storage',
      });
    } else {
      setAuthIntent({
        ...authIntent,
        intent: intent,
        previousPath: '/main',
        intentPath: '/main',
      });
    }
    setModal('login');
  };

  const handleSwitchToNpc = () => {
    // 로그인 되어있을 시
    if (isAuthenticated) {
      setModal('none');
      navigate('/host/manage-storage');
    } else {
      // 로그인 안 되어있을 시
      prepareForLogin('host');
    }
  };

  const handleClickLogin = () => {
    // 로그인 상태일 시 - 로그아웃 로직
    if (isAuthenticated) {
      setModal('none');
      setIsAuthenticated(false);
      setIsAdminAuthenticated(false);
      setPersonalInfo(false);

      removeCookie('access_token', { path: '/' });
      removeCookie('refresh_token', { path: '/' });
      navigate('/main');
    }
    // 로그아웃 상태일 시(로그인 안 되어 있을 시)
    else {
      prepareForLogin('user');
    }
  };

  const handleClickTranslate = () => {
    setIsTranslateOpen(!isTranslateOpen);
  };
  const handleLanguageSelect = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
    window.location.reload(); // 페이지를 리로드하여 언어 변경을 적용
  };
  const getLanguageFromLocal = () => {
    if (localStorage.getItem('language') === 'en') {
      return 'English';
    } else if (localStorage.getItem('language') === 'ko') {
      return '한국어';
    } else if (localStorage.getItem('language') === 'zh') {
      return '中国人';
    } else if (localStorage.getItem('language') === 'ja') {
      return '日本語';
    } else {
      return 'English';
    }
  };

  return (
    <XLayout handleClickXButton={handleClickXButton}>
      <Text>
        <div></div>
        <ButtonWrapper>
          {!isAuthenticated && (
            <FlexButton onClick={handleClickLogin} style={{ marginBottom: 8 }}>
              <ButtonDiv></ButtonDiv>
              {/* <Trans i18nKey="login">Log In</Trans> */}
              {t('login')}
              <ButtonDiv>
                <ButtonImage src={LoginLogo} />
              </ButtonDiv>
            </FlexButton>
          )}
          <FlexButton onClick={handleSwitchToNpc} style={{ marginBottom: 8 }}>
            <ButtonDiv></ButtonDiv>
            <div style={{ color: '#1B303F' }}>Switch to NPC</div>
            <ButtonDiv>
              <ButtonImage src={SwitchLogo} />
            </ButtonDiv>
          </FlexButton>
          <Button onClick={handleClickTranslate}>
            <Flex
              style={{
                color: '#1B303F',
                margin: '0 10px',
                borderBottom: isTranslateOpen ? '2px solid #d9d9d9' : 'none',
              }}
            >
              <ButtonDiv></ButtonDiv>
              <div>{getLanguageFromLocal()}</div>
              <ButtonDiv>
                <ButtonImage src={TranslateLogo} />
              </ButtonDiv>
            </Flex>
            {isTranslateOpen &&
              languages.map((language, index) => (
                <Language key={index} onClick={() => handleLanguageSelect(language[0])}>
                  <ButtonDiv>{language[0] === localStorage.getItem('language') && 'v'}</ButtonDiv>
                  <div>{language[1]}</div>
                  <ButtonDiv></ButtonDiv>
                </Language>
              ))}
          </Button>
        </ButtonWrapper>
        {isAuthenticated ? (
          <LogOutText onClick={handleClickLogin}> {t('logout')}</LogOutText>
        ) : (
          <div></div>
        )}
      </Text>
    </XLayout>
  );
};

const ButtonWrapper = styled.div`
  width: 100%;
`;
const LogOutText = styled.div`
  text-decoration: underline;
  font-size: 0.9375rem;
  color: #828282;
  font-family: 'DM Sans Medium';
`;
const Text = styled.div`
  font-size: 0.875rem;
  font-family: 'DM Sans Regular';
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;
const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 20px;
`;
const FlexButton = styled(Flex)`
  border: 2px solid #d9d9d9;
  border-radius: 30px;
  width: 100%;
  color: #1b303f;
`;
const Button = styled.div`
  border: 2px solid #d9d9d9;
  border-radius: 30px;
  width: 100%;
`;
const Language = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 20px;
  font-family: 'DM Sans Bold';
  font-size: 1.25rem;
  cursor: pointer;
  margin: 0 10px;
`;
const ButtonDiv = styled.div`
  width: 1.875rem;
  height: 1.875rem;
  font-family: 'DM Sans Regular';
`;
const ButtonImage = styled.img`
  height: 1.875rem;
  width: auto;
`;
export default Hamburger;
