import { useLocation, useNavigate, useParams } from 'react-router-dom';
import BookingDate from '../../components/booking/BookingDate';
import BookingQuantity from '../../components/booking/BookingQuantity';
import BookingPay from '../../components/booking/BookingPay';
import BookingInfo from '../../components/booking/BookingInfo';
import BookingFooter from '../../components/footer/BookingFooter';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  ReservationStateType,
  basePriceState,
  checkInDateSelector,
  checkOutDateSelector,
  defaultReservationState,
  reservationState,
  selectedHourState,
  totalPriceSelector,
} from '../../recoil/reservationRecoil';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import BookingHeader from '../../components/header/BookingHeader';
import ProgressBar from '../../components/common/ProgressBar';
import { addHoursAndGetCheckOutDate } from '../../utils/getCheckOut';
import { format, setHours, setMinutes } from 'date-fns';
import { defaultCheckInDate } from '../../utils/roundUpTime';
import { getPlaceById, getRemainingCapacityApi } from '../../apis/placeApi';
import { GetBusinessHourType, GetPlaceType, RemainingCapacityType } from '../../types/storageType';
import { parseOperatingTime, splitBusinessHour } from '../../utils/parseOperatingTime';
import closedDaysFilter, { getTodayStringForShow } from '../../utils/dateFilter';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiResponseType } from '../../apis/adminApi';
import { postBookingApi } from '../../apis/bookingApi';
import { useForm } from 'react-hook-form';
import ConfirmModal from '../../components/common/ConfirmModal';
import { hasPersonalInfoState } from '../../recoil/personalInfo';
import { useTranslation } from 'react-i18next';
import { authenticatedState } from '../../recoil/authenticatedAtom';
import { GetPersonalInfoType, getPersonalInfoApi } from '../../apis/loginApi';
import { Axios, AxiosError } from 'axios';
import Spinner from '../../components/common/Spinner';
import { authIntentState } from '../../recoil/authIntentAtom';

const BookingPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { reset } = useForm();
  const queryClient = useQueryClient();
  const location = useLocation();
  const { id } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const step = searchParams.get('step'); // 'reservation-date', 'quantity-selection', 등
  const [reservationInfo, setReservationInfo] =
    useRecoilState<ReservationStateType>(reservationState);
  const [basePrice, setBasePrice] = useRecoilState(basePriceState);
  const totalPrice = useRecoilValue(totalPriceSelector);
  const [section, setSection] = useState<number>(0);
  const [checkInObj, setCheckInObj] = useState<Date | null>(defaultCheckInDate);
  // const [checkInObj, setCheckInObj] = useRecoilState<Date>(checkInState);
  const [hoursToAdd, setHoursToAdd] = useState<number>(1);
  const [checkOutDate, setCheckOutDate] = useRecoilState(checkOutDateSelector);
  const [isValidTime, setIsValidTime] = useState<boolean>(true);
  const [checkInDate, setCheckInDate] = useRecoilState(checkInDateSelector);
  const [isOverCapacity, setIsOverCapacity] = useState<boolean>(false);
  const [personalInfo, setPersonalInfo] = useRecoilState(hasPersonalInfoState);
  const [selectedHour, setSelectedHour] = useRecoilState(selectedHourState);
  const [isAuthenticated, setIsAuthenticated] = useRecoilState(authenticatedState);
  const [authIntent, setAuthIntent] = useRecoilState(authIntentState);

  // url 파라미터 mapping 위한 객체 생성
  const stepToSectionMapping: { [key: string]: number } = {
    information: 0,
    'date-selection': 1,
    'quantity-selection': 2,
    'pay-selection': 3,
  };

  // step 파라미터에 따라 내부 상태를 업데이트하는 로직
  useEffect(() => {
    const section = stepToSectionMapping[step ?? ''] ?? 0;
    setSection(section);
  }, [step]);

  const {
    data,
    isError: personalInfoError,
    isLoading: personalInfoLoading,
  } = useQuery({
    queryKey: ['personalInfo'],
    queryFn: async (): Promise<ApiResponseType<GetPersonalInfoType>> => getPersonalInfoApi(),
    enabled: !!isAuthenticated && !personalInfo && section === 0,
    retry: (failCount, error) => {
      if ((error as AxiosError).response!.status === 404) return false;
      if (failCount >= 1) return false;
      return true;
    },
  });
  useEffect(() => {
    if (section === 1) {
      if (personalInfo) return;
      else if (data?.data.email && data.data.name && data.data.phoneNumber) {
        // 데이터가 있으면,,, 이 아니라 어떠한 검증을 통해서 사용자가 개인정보 입력했는지 아는게 중요
        // 확인이 되었을 때 더이상 확인하는 로직을 거치지 않도록 상태 변경을 해주는 것.

        setPersonalInfo(true);
      } else {
        navigate('/personal-info');
      }
    }
  }, [data, section]);

  useEffect(() => {
    localStorage.setItem('placeId', id as string);
  }, [id]); // 예약페이지에서 나갈 때 없애주는 값

  const {
    data: placeInfo,
    isLoading: placeIsLoading,
    isError: placeIsError,
  } = useQuery({
    queryKey: ['place', id],
    queryFn: async (): Promise<ApiResponseType<GetPlaceType>> => getPlaceById(Number(id)),
    enabled: !!id || !!localStorage.getItem('placeId'),
  });

  const {
    data: remainingCapacity,
    isLoading: capacityIsLoading,
    isError: capacityIsError,
  } = useQuery({
    // 쿼리 키가 중요한 이유 : 쿼리 키의 변화를 감지하여 데이터를 새로 불러옴.
    queryKey: ['capacity', id, reservationInfo.dropOffAt, reservationInfo.pickUpAt],
    queryFn: async (): Promise<ApiResponseType<RemainingCapacityType>> =>
      getRemainingCapacityApi(Number(id), {
        dropOffAt: reservationInfo.dropOffAt.replace(' ', 'T'),
        pickUpAt: reservationInfo.pickUpAt.replace(' ', 'T'),
      }),
    enabled: !!reservationInfo && (!!id || !!localStorage.getItem('placeId')),
  });
  // checkInDate를 사용자가 현재 날짜에서 바꿨을 시 바로 checkOutDate가 표시되도록 하기.
  useEffect(() => {
    if (checkInObj) {
      const FormattedCheckInDate = format(checkInObj, 'yyyy-MM-dd HH:mm');
      setCheckInDate(FormattedCheckInDate);
      setCheckOutDate(addHoursAndGetCheckOutDate(FormattedCheckInDate, hoursToAdd));
    }
  }, [checkInObj]);

  const {
    getTodayStringForFilter,
    getPrevDayStringForFilter,
    openForPrevDayBusinessHour,
    closedDaysNumbers,
    isOpen24Hours,
  } = closedDaysFilter(placeInfo?.data.businessHours || []);

  const adjustEndTime = (endHour: number, endMinute: number) => {
    let adjustedEndHour = endHour;
    let adjustedEndMinute = endMinute;
    if (endMinute < 10) {
      adjustedEndHour -= 1;
      adjustedEndMinute += 50;
    } else {
      adjustedEndMinute -= 10;
    }
    return { adjustedEndHour, adjustedEndMinute };
  };
  const isNotValidTime = (date: Date, businessHours: GetBusinessHourType[]) => {
    // 선택된 날짜 데이터
    const day = date.getDay();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const todayString = getTodayStringForFilter(date);
    const prevDayString = getPrevDayStringForFilter(date);
    const todayBusinessHour = businessHours.find((bh) => bh.dayOfWeek === todayString);
    const prevDayBusinessHour = businessHours.find((bh) => bh.dayOfWeek === prevDayString);

    // 영업을 하지 않으면 무조건 notValid
    if (todayBusinessHour) {
      const { closed, isSpanToNextDay, businessHour } = todayBusinessHour;

      if (closed) {
        if (openForPrevDayBusinessHour(date, businessHours) && prevDayBusinessHour?.businessHour) {
          console.log('여기');
          const { endHour: prevEndHour, endMinute: prevEndMinute } = parseOperatingTime(
            prevDayBusinessHour.businessHour,
          );
          const { adjustedEndHour: prevAdjustedEndHour, adjustedEndMinute: prevAdjustedEndMinute } =
            adjustEndTime(prevEndHour, prevEndMinute);

          return (
            new Date(date) >
            new Date(
              setHours(setMinutes(new Date(date), prevAdjustedEndMinute), prevAdjustedEndHour),
            )
          );
        } else return true;
      } else if (businessHour) {
        // todayBusinessHour
        const { startHour, startMinute, endHour, endMinute } = parseOperatingTime(businessHour);

        if (checkInObj && checkInObj < setHours(setMinutes(new Date(), startMinute), startHour)) {
          setCheckInObj(setHours(setMinutes(new Date(), startMinute), startHour));
        } // => 영업시간 전에 예약하러 들어왔을 떄 오픈시간으로 디폴트 맞춰주기..

        // 마감 시간 10분 전 로직 추가
        const { adjustedEndHour: todayAdjustedEndHour, adjustedEndMinute: todayAdjustedEndMinute } =
          adjustEndTime(endHour, endMinute);

        // 24시간 영업인지 확인하던 로직 제거. 그 다음날이 24시간이 아닐수도 있다.
        if (isSpanToNextDay) {
          if (hours > todayAdjustedEndHour && hours < startHour) {
            return true;
          }
          if (hours > todayAdjustedEndHour) {
            if (hours > startHour) {
              return false;
            } else if (hours === startHour) {
              if (minutes >= startMinute) {
                return false;
              } else return true;
            } else {
              return true;
            }
          } else if (hours === todayAdjustedEndHour) {
            if (minutes <= todayAdjustedEndMinute) return false;
            else return true;
          } else if (hours < startHour) {
            if (hours < todayAdjustedEndHour) return false;
            else if (hours === todayAdjustedEndHour) {
              if (minutes <= todayAdjustedEndMinute) return false;
              else return true;
            }
          } else if (hours === startHour) {
            if (minutes < startMinute) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          // 24시간 영업도 아니고 익일영업도 아닌 케이스
          return (
            hours < startHour ||
            (hours === startHour && minutes < startMinute) ||
            hours > todayAdjustedEndHour ||
            (hours === todayAdjustedEndHour && minutes > todayAdjustedEndMinute)
          );
        }
        // }
      }
    }
  };

  useEffect(() => {
    if (checkOutDate && placeInfo) {
      const checkOutObj = new Date(checkOutDate);
      if (isNotValidTime(checkOutObj, placeInfo?.data.businessHours || [])) {
        setIsValidTime(false);
      } else {
        setIsValidTime(true);
      }
    } else if (placeInfo) {
      const defaultCheckInDateString = format(defaultCheckInDate, 'yyyy-MM-dd HH:mm');
      const defaultCheckOutDateString = addHoursAndGetCheckOutDate(defaultCheckInDateString, 1);
      const checkOutObj = new Date(defaultCheckOutDateString);
      if (isNotValidTime(checkOutObj, placeInfo?.data.businessHours || [])) {
        setIsValidTime(false);
      } else {
        setIsValidTime(true);
      }
    }
  }, [checkOutDate, placeInfo]);

  // 사용자로부터 이용시간을 클릭 받아 이용할 시간에 대한 상태 지정과 checkOutDate 지정.
  // 함수를 정의하는 곳은 BookingPage이므로, Date컴포넌트 내에서 컨트롤하는 hours와 price값을 받아서 상태변경
  const updateReservationDetails = (hours: number, basePrice: number) => {
    setHoursToAdd(hours);
    setReservationInfo({ ...reservationInfo, period: hours, paymentAmount: basePrice });
    setBasePrice(basePrice); // 수량을 곱하기 전에 base가 되는 price를 설정.
    if (checkInObj) {
      setCheckOutDate(addHoursAndGetCheckOutDate(format(checkInObj, 'yyyy-MM-dd HH:mm'), hours));
    } else {
      setCheckInObj(new Date());
      // 사용자가 날짜를 바꾸려다가 취소한 경우 or 딱히 선택한 날짜가 없는 경우
      setCheckOutDate(addHoursAndGetCheckOutDate(format(new Date(), 'yyyy-MM-dd HH:mm'), hours));
    }
  };
  useEffect(() => {
    if (remainingCapacity && remainingCapacity.data.remainingCapacity === 0) {
      setIsOverCapacity(true);
    } else {
      setIsOverCapacity(false);
    }
  }, [remainingCapacity]);
  const handleIncreaseBagCount = () => {
    if (
      remainingCapacity &&
      remainingCapacity?.data.remainingCapacity < reservationInfo.numOfBags + 1
    ) {
      setIsOverCapacity(true);
      return;
    }
    setReservationInfo((prevInfo) => ({
      ...prevInfo,
      numOfBags: prevInfo.numOfBags + 1,
    }));
  };

  const handleDecreaseBagCount = () => {
    if (placeInfo && placeInfo.data.capacity >= reservationInfo.numOfBags - 1) {
      setIsOverCapacity(false);
    }
    setReservationInfo((prevInfo) => ({
      ...prevInfo,
      numOfBags: Math.max(1, prevInfo.numOfBags - 1),
    }));
  };

  // useEffect로 totalPrice가 갱신될 때마다 reservationInfo의 price를 업데이트
  useEffect(() => {
    setReservationInfo((prevInfo) => ({
      ...prevInfo,
      paymentAmount: totalPrice, // totalPriceSelector가 계산한 최신 가격을 반영
    }));
  }, [totalPrice]); // totalPrice가 변경될 때마다 실행

  const postReservationMutation = useMutation({
    mutationFn: (reservationData: ReservationStateType) => postBookingApi(reservationData),
    onSuccess: (data) => {
      reset();
      queryClient.refetchQueries({ queryKey: ['bookings', 'WAIT'] });
      setReservationInfo(defaultReservationState);
      setCheckInObj(defaultCheckInDate);
      localStorage.removeItem('placeId');
      setSelectedHour(1);
      navigate(`/booking-success/${data.data.booking.bookingId}`);
    },
    onError: (error) => {
      console.error('postReservationMutation error', error);
      if ((error as AxiosError).response?.status === 409) {
        alert('The storage limit has been exceeded.');
      } else {
        alert(t(`modal.reservation_error : ${error}`));
      }
    },
  });

  const confirmBooking = () => {
    const { numOfBags, paymentAmount, dropOffAt, pickUpAt, period, type, paymentMethod } =
      reservationInfo;

    const postData = {
      placeId: placeInfo?.data.placeId,
      dropOffAt: dropOffAt.replace(' ', 'T'),
      pickUpAt: pickUpAt.replace(' ', 'T'),
      period,
      numOfBags,
      paymentAmount,
      paymentMethod,
      type,
    };
    postReservationMutation.mutate(postData);
  };

  const showTodayBusinessHour = placeInfo?.data.businessHours?.find(
    (it) => it.dayOfWeek === getTodayStringForShow(placeInfo?.data.businessHours),
  )?.businessHour;
  if (personalInfoLoading || placeIsLoading || postReservationMutation.isPending) {
    return <Spinner />;
  }
  if (personalInfoError || placeIsError) {
    alert(t('unexpected_error'));
    return null;
  }

  return (
    <Container>
      <ConfirmModal
        validateForms={confirmBooking}
        bigText={t('bookingPage.are_you_sure')}
        smallTextFirst={t('bookingPage.confirm_reservation_1')}
        smallTextSecond={t('bookingPage.confirm_reservation_2')}
        cancelText={'NO'}
        confirmText={'YES'}
      />
      {step !== 'information' && (
        <BookingHeader section={section} setSection={setSection} id={id} />
      )}
      {step !== 'information' && <ProgressBar section={section} />}
      {step === 'date-selection' && (
        <BookingDate
          businessHours={placeInfo ? placeInfo.data.businessHours : []}
          isValidTime={isValidTime}
          checkOutDate={checkOutDate}
          hoursToAdd={hoursToAdd}
          setHoursToAdd={setHoursToAdd}
          getHoursAndPriceToAdd={updateReservationDetails}
          checkInObj={checkInObj}
          setCheckInObj={setCheckInObj}
          operatingTime={
            isOpen24Hours()
              ? t('operateDate.day_24')
              : splitBusinessHour(showTodayBusinessHour)?.endTime === '23:59'
              ? `${splitBusinessHour(showTodayBusinessHour)?.startTime} ~ 24:00`
              : showTodayBusinessHour || t('CLOSE')
          }
        />
      )}
      {step === 'quantity-selection' && (
        <BookingQuantity
          handleIncreaseBagCount={handleIncreaseBagCount}
          handleDecreaseBagCount={handleDecreaseBagCount}
          // capacity={placeInfo?.data.capacity}
          capacity={remainingCapacity?.data.remainingCapacity}
          isOverCapacity={isOverCapacity}
        />
      )}
      {step === 'pay-selection' && <BookingPay />}
      {step === 'information' && <BookingInfo placeData={placeInfo?.data} />}
      <BookingFooter
        isOverCapacity={isOverCapacity}
        checkInObj={checkInObj}
        checkOutDate={checkOutDate}
        isValidTime={isValidTime}
        section={section}
        setSection={setSection}
        id={id}
        reservationInfo={reservationInfo}
        nextAvailable="true"
      />
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  height: calc(100% - 70px);
  background-color: #f6f6f6;
  overflow: scroll;

  /* Chrome, Safari */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Firefox */
  scrollbar-width: none;

  /* Internet Explorer */
  -ms-overflow-style: none;
`;
export default BookingPage;
