import styled from '@emotion/styled';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import { useRecoilState, useRecoilValue } from 'recoil';
import Hamburger from './components/main/Hamburger';
import { modalState } from './recoil/modalAtom';
import { authIntentState } from './recoil/authIntentAtom';
import LoginModal from './pages/login/LoginModal';

interface LayoutType {
  children?: JSX.Element;
  footer: string;
  header: string;
}

const Layout = ({ children, footer, header }: LayoutType): JSX.Element => {
  const isMobile = window.innerWidth <= 600;
  const modal = useRecoilValue(modalState);
  const [authIntent, setAuthIntent] = useRecoilState(authIntentState);
  const { intent } = authIntent;
  return (
    <>
      {intent === 'admin' ? (
        <AdminContainer>
          <Header header={header} />
          {children}
          {footer !== 'none' && <Footer footer={footer} />}
        </AdminContainer>
      ) : !isMobile ? (
        <WebContainer>
          {modal === 'menu' && <Hamburger />}
          {modal === 'login' && <LoginModal />}
          <Header header={header} />
          {children}
          {footer !== 'none' && <Footer footer={footer} />}
        </WebContainer>
      ) : (
        <MobileContainer>
          {modal === 'menu' && <Hamburger />}
          {modal === 'login' && <LoginModal />}
          <Header header={header} />
          {children}
          {footer !== 'none' && <Footer footer={footer} />}
        </MobileContainer>
      )}
    </>
  );
};

const WebContainer = styled.div`
  box-sizing: content-box;
  margin: 0 auto;
  /* border: 12px solid #1b303f; */
  border: 8px solid #1b303f;

  border-radius: 30px;
  width: 360px;
  height: 640px;
  position: relative;
  overflow: hidden;
`;
const MobileContainer = styled.div`
  box-sizing: content-box;
  margin: 0 auto;
  width: 100%; /* 모바일 화면에서는 너비를 화면 전체로 설정 */
  min-width: 360px; /* 최대 너비를 360px로 제한 */
  height: 100vh; /* 모바일 화면에서는 화면 전체 높이로 설정 */
  /* border: 1px solid black; // 없앨 것. */
  position: relative;
  overflow: hidden;
`;

const AdminContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: scroll;
`;

export default Layout;
