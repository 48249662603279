import styled from '@emotion/styled';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { ApiResponseType } from '../../apis/adminApi';
import { PersonalInfoType, hasPersonalInfoState } from '../../recoil/personalInfo';
import { postPersonalInfoApi } from '../../apis/loginApi';
import { useRecoilState } from 'recoil';
import { ErrorMessage } from '../../assets/css/RegisterCommonStyles';
import { useNavigate } from 'react-router-dom';
import PrevButton from '../../assets/images/PrevButton.png';
import LugezLogo from '../../assets/images/LugezLogoGrey.png';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useEffect, useState } from 'react';
import { splitFullNumber } from '../../utils/phoneNumber';
import { useTranslation } from 'react-i18next';
import PersonalInfoRequiredDocument from './PersonalInfoRequiredDocument';
import Spinner from '../../components/common/Spinner';
import { authIntentState } from '../../recoil/authIntentAtom';
import { authenticatedState } from '../../recoil/authenticatedAtom';
const PersonalInfoPage = () => {
  const { t } = useTranslation();
  const [personalInfo, setPersonalInfo] = useRecoilState(hasPersonalInfoState);
  const [phone, setPhone] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('');
  const [nationalNumber, setNationalNumber] = useState<string>('');
  const [isAgreeChecked, setIsAgreeChecked] = useState<boolean>(false);
  const [isNeverAskChecked, setIsNeverAskChecked] = useState<boolean>(false);
  const [isAgreePageOpen, setIsAgreePageOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const handleChange = (value: string, data: any) => {
    const nationalNumberForm = splitFullNumber(data.format);
    setPhone(value);
    setNationalNumber(value.slice(data.dialCode.length));
    setCountryCode(`+${data.dialCode}`);
    // if (data.dialCode === '86') {
    //   // 중국
    //   const formattedNumber = formatNumberWithSpace(
    //     value.slice(data.dialCode.length),
    //     '... .... ....',
    //   );

    //   setNationalNumber(formattedNumber);
    // } else if (data.dialCode === '81') {
    //   // 일본
    //   const formattedNumber = formatNumberWithSpace(
    //     `0${value.slice(data.dialCode.length)}`,
    //     '...-....-....',
    //   );
    //   setNationalNumber(formattedNumber);
    // } else if (data.dialCode === '1') {
    //   //미국
    //   const formattedNumber = formatNumberWithSpace(
    //     value.slice(data.dialCode.length),
    //     '(...) ...-....',
    //   );
    //   setNationalNumber(formattedNumber);
    // } else {
    //   const formattedNumber = formatNumberWithDash(
    //     value.slice(data.dialCode.length),
    //     nationalNumberForm,
    //   );
    //   setNationalNumber(formattedNumber);
    // }
  };
  const id = localStorage.getItem('placeId');
  useEffect(() => {
    if (!id) {
      alert('Unauthorized access');
      navigate('/main');
    }
  }, [id]);
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();
  const { reset } = useForm();
  const queryClient = useQueryClient();
  const name = watch('name');
  const email = watch('email');

  const validateName = (value: string) => {
    if (!value) return '이름을 입력해주세요';
    if (!/^[\p{L}\p{M}\p{Zs}.'-]{2,}$/u.test(value)) return '2글자 이상 적어주세요.';
    return true;
  };
  const validateEmail = (value: string) => {
    if (!value) return '이메일을 입력해주세요';
    if (!/\S+@\S+\.\S+/.test(value)) return '올바른 이메일 형식이 아닙니다.';
    return true;
  };

  const postPersonalInfoMutation = useMutation<ApiResponseType<{}>, Error, PersonalInfoType>({
    mutationFn: (formData: PersonalInfoType) => postPersonalInfoApi(formData),
    onSuccess: (data: ApiResponseType<any>) => {
      reset();
      queryClient.refetchQueries({ queryKey: ['personalInfo'] });
      if (id) {
        navigate(`/booking/${id}?step=date-selection`);
        if (isNeverAskChecked) {
          setPersonalInfo(true);
          // 다시묻지 않기를 체크한다면 모든 필드가 채워지는 것으로 간주,
          // 다음에 다시 예약섹션에 진입 시 데이터 호출을 안할 수 있도록 미리 상태변경
        }
      } else {
        navigate('/main');
      }
    },
    onError: (error) => {
      console.log('postPersonalInfoMutation error', error);
      alert(t('personalInfo.post_error'));
    },
  });

  const onSubmit = () => {
    // 현재 로직으로서는 필드 모두 입력 + 다시묻지않기 x 인 경우는 거르지 못한다.
    if (isNeverAskChecked && !nationalNumber) {
      const formData: PersonalInfoType = {
        email,
        name,
        rawPhoneNumber: '+8201000000000',
      };
      postPersonalInfoMutation.mutate(formData);
    } else if (!isNeverAskChecked && !nationalNumber) {
      const formData: PersonalInfoType = {
        email,
        name,
      };
      postPersonalInfoMutation.mutate(formData);
    } else {
      const formData: PersonalInfoType = {
        email,
        name,
        rawPhoneNumber: `+${phone}`,
      };
      postPersonalInfoMutation.mutate(formData);
    }
  };
  const handleGoToInfo = () => {
    if (isAgreePageOpen) {
      setIsAgreePageOpen(false);
    } else {
      const placeId = localStorage.getItem('placeId');
      navigate(`/booking/${placeId}?step=information`);
    }
  };
  const handleAgreeCheck = () => {
    setIsAgreeChecked(!isAgreeChecked);
  };
  const handleNeverAskCheck = () => {
    setIsNeverAskChecked(!isNeverAskChecked);
  };
  if (postPersonalInfoMutation.isPending) {
    return <Spinner />;
  }
  return (
    <Container style={{ justifyContent: isAgreePageOpen ? 'flex-start' : 'space-between' }}>
      <PrevImage onClick={handleGoToInfo} src={PrevButton} />
      {isAgreePageOpen ? (
        <>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Logo src={LugezLogo} />
          </div>
          <PersonalInfoRequiredDocument />
        </>
      ) : (
        <>
          <div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Logo src={LugezLogo} />
            </div>
            <div style={{ paddingBottom: 40 }}>
              <div style={{ marginBottom: 20 }}>
                <Title>{t('personalInfo.provide_contact_1')}</Title>
                <Title>{t('personalInfo.provide_contact_2')}</Title>
              </div>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Flex>
                  <Left>
                    <div>Name *</div>
                  </Left>
                  <InfoInput
                    type="text"
                    placeholder="Ven Jim"
                    id="name"
                    {...register('name', {
                      validate: validateName,
                    })}
                    style={{
                      borderColor: errors?.name?.message ? '#f74726' : name ? '#0094ff' : '#ffffff',
                    }}
                  />
                </Flex>
                {errors.name?.message && (
                  <ErrorMessage role="alert">{errors?.name?.message.toString()}</ErrorMessage>
                )}
                <Flex>
                  <Left>Email *</Left>
                  <InfoInput
                    type="email"
                    placeholder="lugez@gmail.com"
                    id="email"
                    {...register('email', {
                      validate: validateEmail,
                    })}
                    style={{
                      borderColor: errors?.email?.message
                        ? '#f74726'
                        : email
                        ? '#0094ff'
                        : '#ffffff',
                    }}
                  />
                </Flex>
                {errors.email?.message && (
                  <ErrorMessage role="alert">{errors?.email?.message.toString()}</ErrorMessage>
                )}
                <Flex>
                  <StyledPhoneInput
                    country={'kr'}
                    value={phone}
                    // disableDropdown={false}
                    onChange={handleChange}
                    inputProps={{
                      name: 'phoneNumber',
                      required: true,
                      // autoFocus: true,
                    }}
                    enableSearch={true} // 드롭다운에서 국가 검색 활성화
                    searchPlaceholder="Search" // 검색 입력 필드의 플레이스홀더
                  ></StyledPhoneInput>
                </Flex>
                <CheckContainer style={{ marginBottom: 9, marginTop: 14 }}>
                  <CheckText style={{ marginLeft: 0 }}>{t('personalInfo.optional')}</CheckText>
                </CheckContainer>
                <CheckContainer style={{ marginBottom: 9 }}>
                  <input
                    type="checkbox"
                    checked={isAgreeChecked}
                    onChange={handleAgreeCheck}
                  ></input>
                  <CheckText onClick={() => setIsAgreePageOpen(true)}>
                    {t('personalInfo.required')}
                    <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                      {t('personalInfo.agree_check')}
                    </span>
                  </CheckText>
                </CheckContainer>
                <CheckContainer>
                  <input
                    type="checkbox"
                    checked={isNeverAskChecked}
                    onChange={handleNeverAskCheck}
                  ></input>
                  <CheckText> {t('personalInfo.continue_to_use')}</CheckText>
                </CheckContainer>
                {errors.phoneNumber?.message && (
                  <ErrorMessage role="alert">
                    {errors?.phoneNumber?.message.toString()}
                  </ErrorMessage>
                )}
                <button
                  disabled={!isAgreeChecked || !isValid}
                  type="submit"
                  style={{ display: 'none' }}
                  id="hiddenSubmitButton"
                >
                  NEXT
                </button>
              </Form>
            </div>
          </div>

          <Next style={{ backgroundColor: isValid && isAgreeChecked ? '#1b303f' : '#D9D9D9' }}>
            <label htmlFor="hiddenSubmitButton">NEXT</label>
          </Next>
        </>
      )}
    </Container>
  );
};
const AgreeContainer = styled.div`
  width: 100%;
  padding-top: 20;
`;
const StyledPhoneInput = styled(PhoneInput)`
  .form-control {
    all: unset;
    position: relative;
    font-size: 0.75rem;
    margin-bottom: 0 !important;
    margin-left: 0;
    outline: none;
    padding-left: 7.0625rem;
    padding-top: 0.8125rem;
    padding-bottom: 0.8125rem;
  }
  .flag-dropdown {
    width: 6.25rem;
    border: none;
    border-right: 1px solid #1b303f;
  }
  .selected-flag {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .react-tel-input .flag-dropdown .selected-flag .flag .arrow {
    left: 30px;
  }
`;
const CheckText = styled.div`
  font-size: 0.75rem;
  font-family: 'DM Sans Regular';
  margin-left: 10px;
`;
const CheckContainer = styled.div`
  display: flex;
  padding-left: 10px;
`;
const Left = styled.div`
  display: flex;
  align-items: center;
  padding-left: 8px;
  font-size: 0.75rem;
  width: 6.25rem;
  border-right: 1px solid #1b303f;
`;

const Flex = styled.div`
  display: flex;
  border: 1px solid #1b303f;
  margin-bottom: 5px;
`;
const Title = styled.div`
  font-size: 1.375rem;
  font-family: 'DM Sans ExtraBold';
`;
const Logo = styled.img`
  margin-top: 40px;
  margin-bottom: 50px;
  width: auto;
  height: 2.5rem;
`;
const PrevImage = styled.img`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 3.125rem;
  height: 3.125rem;
`;
const Container = styled.div`
  padding: 40px 20px 20px 20px;
  width: 100%;
  height: 100%;
  /* position: relative; */
  display: flex;
  flex-direction: column;
  overflow: scroll;
  /* Chrome, Safari */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Firefox */
  scrollbar-width: none;

  /* Internet Explorer */
  -ms-overflow-style: none;
`;
const InfoInput = styled.input`
  all: unset;
  padding: 13px;
  font-size: 0.75rem;
  ::placeholder {
    color: #d9d9d9;
  }
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const Next = styled.div`
  width: 100%;
  font-size: 1.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'DM Sans ExtraBold';
  color: #ffffff;
  /* background-color: #1b303f; */
  border-radius: 30px;
  padding: 7px 0;
`;
export default PersonalInfoPage;
