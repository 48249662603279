import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { authIntentState } from './recoil/authIntentAtom';
import { useCookies } from 'react-cookie';

const ProtectedRoute = () => {
  const [cookies] = useCookies(['access_token', 'refresh_token']);
  const location = useLocation();
  const [authIntent, setAuthIntent] = useRecoilState(authIntentState);
  const searchParams = new URLSearchParams(location.search);
  const step = searchParams.get('step');
  const { id } = useParams();
  useEffect(() => {
    // if (!isAuthenticated) {
    if (!(cookies.access_token && cookies.refresh_token)) {
      // console.log(location.pathname, 'pathname');
      // location.pathname에는 parameter가 포함되지 않음
      const newPathName =
        id && location.pathname === `/booking/${id}`
          ? `${location.pathname}?step=information`
          : location.pathname === '/personal-info'
          ? '/main'
          : location.pathname.includes('host')
          ? '/host/manage-storage'
          : location.pathname;
      setAuthIntent({
        ...authIntent,
        previousPath: '/main',
        intentPath: newPathName,
      });
    } else return;
  }, [cookies.access_token, cookies.refresh_token]);

  const returnComponent = () => {
    if ((step && step === 'information') || (cookies.access_token && cookies.refresh_token)) {
      return <Outlet />;
      // 실제로는 ProtectedRoute내의 중첩된 컴포넌트가 렌더링되도록 함.
    } else {
      return <Navigate to="/log-in" state={{ from: location }} />;
      // Navigate는 단순한 컴포넌트 렌더링이 아니라 리다이렉트 동작을 수행
    }
  };
  return returnComponent();
};

export default ProtectedRoute;
