import styled from '@emotion/styled';
import RegisterFooter from '../../components/footer/RegisterFooter';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import RegisterAddr from '../../components/register/RegisterAddr';
import RegisterCategory from '../../components/register/RegisterCategory';
import RegisterAbout from '../../components/register/RegisterAbout';
import RegisterFacilities from '../../components/register/RegisterFacilities';
import RegisterDetailAddr from '../../components/register/RegisterDetailAddr';
import RegisterOwner from '../../components/register/RegisterOwner';
import { useRegisterNavigation } from '../../hooks/useRegisterNavigation';
import RegisterContact from '../../components/register/RegisterContact';
import RegisterImage from '../../components/register/RegisterImage';
import { useRecoilState } from 'recoil';
import {
  ValidType,
  defaultRegistrationState,
  defaultStepValidState,
  registrationValidState,
  storageRegistrationState,
} from '../../recoil/storageRecoil';
import { RegisterPlaceType } from '../../types/storageType';
import { useForm, FormProvider } from 'react-hook-form';
import RegisterQuantity from '../../components/register/RegisterQuantity';
import axios from 'axios';
import { businessHoursState, defaultEachDayOn } from '../../recoil/businessHourAtom';
import {
  GetPresignedUrlType,
  PostPlaceResponseType,
  getPresignedUrlApi,
} from '../../apis/placeApi';
import { postPlaceApi } from '../../apis/placeApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResponseType } from '../../apis/adminApi';
import ConfirmModal from '../../components/common/ConfirmModal';
import Spinner from '../../components/common/Spinner';
import { authIntentState } from '../../recoil/authIntentAtom';
import { authenticatedState } from '../../recoil/authenticatedAtom';

type Step =
  | 'category'
  | 'about'
  | 'facilities'
  | 'quantity'
  | 'addr'
  | 'detailAddr'
  | 'owner'
  | 'contact'
  | 'image';

const RegisterStoragePage = () => {
  const { reset } = useForm();
  const queryClient = useQueryClient();
  const [isDayOn, setIsDayOn] = useRecoilState(businessHoursState);
  const [presignedUrl, setPresignedUrl] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [originalImageSrc, setOriginalImageSrc] = useState<string>('');
  const [openCropper, setOpenCropper] = useState<boolean>(false);
  const [cropData, setCropData] = useState('');
  const [isStepValid, setIsStepValid] = useRecoilState<ValidType>(registrationValidState);
  const [isNextEnabled, setIsNextEnabled] = useState(false);
  const [registerSection, setRegisterSection] = useState<number>(0);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const step = searchParams.get('step');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { steps } = useRegisterNavigation(setRegisterSection);
  const navigate = useNavigate();
  const [storageRegistration, setStorageRegistration] =
    useRecoilState<RegisterPlaceType>(storageRegistrationState);
  const [uploadUrlTried, setUploadUrlTried] = useState(false);

  // methods 내의 defaultValues에 다른 키에 대한 값도 추가해야 하나
  const { name, description } = storageRegistration;
  const methods = useForm({
    mode: 'onTouched',
    // shouldUnregister: true,
    defaultValues: {
      name,
      description,
    },
  });

  // 새로고침 했을 때 registerSection의 값이 초기화 된다면,
  // step 파라미터를 가져와서 그에 대한 인덱스로 다시 값을 저장
  useEffect(() => {
    if (step) {
      const sectionIndex = steps.indexOf(step);
      if (sectionIndex !== -1) {
        setRegisterSection(sectionIndex);
      }
    }
  }, [step, steps]);

  // step 파라미터가 변경될 때 isNextEnabled 상태 업데이트
  useEffect(() => {
    if (step) {
      const isValid = isStepValid[step as keyof ValidType];
      setIsNextEnabled(isValid);
    }
  }, [step, isStepValid]);

  // footer에서는 섹션 이동만, 마지막 섹션에서 footer의 등록하기 버튼을 누르면 모달을 띄움.
  // 최종적으로 모달은 page내에 렌더링 되어 page가 전달하는 등록 함수를 수행 .
  const validateForms = () => {
    return methods.handleSubmit(onSubmit)();
  };

  const postPlaceMutation = useMutation<
    ApiResponseType<PostPlaceResponseType>,
    Error,
    RegisterPlaceType
  >({
    mutationFn: (registerData: RegisterPlaceType) => postPlaceApi(registerData),
    onSuccess: (data: ApiResponseType<PostPlaceResponseType>) => {
      reset();
      queryClient.refetchQueries({ queryKey: ['place'] });

      initializeStates();
      navigate('/host/register-success');
    },
    onError: (error) => {
      console.error('post error', error);
      alert('보관소 등록에 실패하였습니다. 관리자에게 문의해주세요.');
    },
  });
  const onSubmit = async () => {
    if (postPlaceMutation.isPending) {
      console.log('is mutating ');
      return;
    } else {
      if (!selectedFile) {
        console.log('selectedFile is required');
        return;
      }
      if (!uploadUrlTried) {
        const uploadS3Response = await uploadUrlToS3(presignedUrl, selectedFile);
      }

      postPlaceMutation.mutate(storageRegistration);
    }
  };

  const initializeStates = () => {
    setStorageRegistration(defaultRegistrationState);
    setIsStepValid(defaultStepValidState);
    setIsDayOn({ ...isDayOn, isAllDayOn: false, isEachDayOn: defaultEachDayOn });
    setOriginalImageSrc('');
    setSelectedFile(null);
    setCropData('');
  };

  // 이미지 input을 조작했을 때('기기에서 업로드'버튼을 눌렀을 때) 제일 처음 실행되는 함수
  // selectedFile과 원본 imageSrc를 업데이트.
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0]; // 사용자가 선택한 파일 데이터
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader(); // 파일을 읽을 reader 객체 생성
      reader.onload = (e) => {
        // 파일 읽기가 완료되면 실행될 onload 함수 정의
        const result = e?.target?.result;
        setOriginalImageSrc(result as string);
      };
      reader.readAsDataURL(file); // 파일 읽기 시작
      setOpenCropper(true); // cropper를 열어도 된다는 의미의 true
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      } // 파일의 참조가 생기면 초기화 하여 동일한 파일에 대한 참조를 다시 실행할 수 있도록 함.
    }
  };
  // 화면에 들어온 최종 이미지 삭제 함수
  const handleDeleteImage = () => {
    setOriginalImageSrc('');
    setSelectedFile(null);
    setCropData('');
  };

  const dataURLtoBlob = (dataUrl: string) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };
  const blobToFile = (blob: Blob, fileName: string): File => {
    return new File([blob], fileName, { type: blob.type });
  };

  const getPresignedUrl = async (ext: string) => {
    try {
      const response: GetPresignedUrlType = await getPresignedUrlApi(ext);

      return response.data;
    } catch (error) {
      console.error(error, 'presigned error');
    }
  };
  const uploadUrlToS3 = async (url: string, file: File) => {
    try {
      const response = await axios.put(url, file);
      console.log(response.data, 'upload s3');
      setUploadUrlTried(true);
    } catch (error) {
      console.error(error, 'upload error');
    }
  };
  // crop된 이미지 적용 후 file객체로 변환하여 selectedFile에 다시 저장하는 함수
  const handleCrop = async (croppedImage: string) => {
    setCropData(croppedImage);
    const blob = dataURLtoBlob(croppedImage);
    const file = blobToFile(blob, selectedFile ? selectedFile.name : 'croppedImage.jpg');
    // 최종적으로 만들어진 파일의 확장자명을 사용하여 presignedUrl 얻기
    const presignedResponse = await getPresignedUrl(file.type.split('/')[1]);
    // 파일의 확장자를 보내어 presignedUrl과 key를 받아온다.
    // 여기서 확장자가 잘못되어 데이터를 못 받아오거나, 잘 보냈어도 catch로 잡히면
    // 에러를 표시해주어야 한다.
    if (presignedResponse?.key && presignedResponse?.presignedUrl) {
      setStorageRegistration({
        ...storageRegistration,
        pictures: [
          {
            key: presignedResponse?.key,
            originalFileName: file.name,
            mimeType: file.type,
            fileSize: file.size,
          },
        ],
      });
      setPresignedUrl(presignedResponse?.presignedUrl);
    } else {
      alert('이미지 저장에 실패하였습니다. 관리자에게 문의해주세요');
      setOpenCropper(false);
      handleDeleteImage();
    }

    setSelectedFile(file);
    setOpenCropper(false);
  };

  useEffect(() => {
    if (cropData) {
      setIsStepValid((prev) => ({
        ...prev,
        image: true,
      }));
    } else {
      setIsStepValid((prev) => ({
        ...prev,
        image: false,
      }));
    }
  }, [cropData]);

  const stepComponents: Record<Step, JSX.Element> = {
    category: <RegisterCategory />,
    about: <RegisterAbout />,
    facilities: <RegisterFacilities />,
    quantity: <RegisterQuantity />,
    addr: <RegisterAddr />,
    detailAddr: <RegisterDetailAddr />,
    owner: <RegisterOwner />,
    contact: <RegisterContact />,
    image: (
      <RegisterImage
        handleFileChange={handleFileChange}
        handleDeleteImage={handleDeleteImage}
        handleCrop={handleCrop}
        openCropper={openCropper}
        originalImageSrc={originalImageSrc}
        setOpenCropper={setOpenCropper}
        cropData={cropData}
        fileInputRef={fileInputRef} // 파일 입력 필드 참조 전달
      />
    ),
  };
  const handleGoOut = () => {
    navigate('/host/manage-storage');
    initializeStates();
  };
  if (postPlaceMutation.isPending) {
    return <Spinner />;
  }
  return (
    <Container>
      <ConfirmModal
        validateForms={validateForms}
        bigText={'환영합니다!'}
        smallTextFirst={'보관소 등록을'}
        smallTextSecond={'완료하시겠습니까?'}
        cancelText={'취소'}
        confirmText={'등록'}
      />
      <FormProvider {...methods}>
        <div>
          <div
            onClick={handleGoOut}
            style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
          >
            <CancelButton>나가기</CancelButton>
          </div>
          {step && stepComponents[step as Step]}
          {!openCropper && (
            <RegisterFooter
              isMutating={postPlaceMutation.isPending}
              isNextEnabled={isNextEnabled}
              registerSection={registerSection}
              setRegisterSection={setRegisterSection}
              // validateForms={validateForms}
            />
          )}
        </div>
      </FormProvider>
    </Container>
  );
};

const CancelButton = styled.div`
  margin-bottom: 8px;
  font-size: 0.75rem;
  color: #d7d7d7;
  padding: 3px 9px;
  align-self: flex-end;
  border: 2px solid #d7d7d7;
  border-radius: 30px;
  cursor: pointer;
`;
const Container = styled.div`
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px 0 20px;
  overflow: scroll;

  /* Chrome, Safari */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Firefox */
  scrollbar-width: none;

  /* Internet Explorer */
  -ms-overflow-style: none;
`;
export default RegisterStoragePage;
