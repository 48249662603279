import styled from '@emotion/styled';
import CancelButton from '../../assets/images/CancelButton.png';
// import LugezLogo from '../../assets/images/LugezLogo.png';
import LugezWaterMark from '../../assets/images/LugezLogoGrey.png';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { modalState } from '../../recoil/modalAtom';
import HomeButton from '../../assets/images/Home.png';
interface XLayoutType {
  children: JSX.Element;
  handleClickXButton: () => void;
}
const XLayout = ({ children, handleClickXButton }: XLayoutType) => {
  const [modal, setModal] = useRecoilState(modalState);
  return (
    <Container>
      <FlexWrapper>
        <XButton
          src={modal === 'none' ? HomeButton : CancelButton}
          onClick={handleClickXButton}
        ></XButton>
        <Logo>
          <img style={{ width: 'auto', height: '2.5rem' }} src={LugezWaterMark} />
        </Logo>
        {children}
        <div style={{ width: 40, height: 40 }}></div>
      </FlexWrapper>
    </Container>
  );
};

const Logo = styled.div`
  position: absolute;
  z-index: -1;
  width: calc(100% - 40px);
  padding-top: 80px;
  display: flex;
  justify-content: center;
`;
const XButton = styled.img`
  width: 2.5rem;
  height: 2.5rem;
`;
const FlexWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  justify-content: space-between;
`;
const Container = styled.div`
  /* width: 100%;
  height: 100%; */
  padding: 20px;
  padding-top: 20px; // safe-area 때문에 top만 10 더해줌
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 900;
  position: absolute;
  background-color: #f6f6f6;
`;
export default XLayout;
