import React, { useEffect } from 'react';
import './App.css';
import Layout from './Layout';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { pages } from './pages';
import AdminPlacesPage from './admin/pages/AdminPlacesPage';
import { useCookies } from 'react-cookie';
import { useRecoilState } from 'recoil';
import { authenticatedState } from './recoil/authenticatedAtom';
import TokenRefresher from './apis/utils/TokenRefresher';
import AdminPage from './admin/pages/AdminPage';
import LandingPage from './landing/LandingPage';
import { hasPersonalInfoState } from './recoil/personalInfo';
import { useQuery } from '@tanstack/react-query';
import { ApiResponseType } from './apis/adminApi';
import { GetPersonalInfoType, getPersonalInfoApi } from './apis/loginApi';
import ProtectedRoute from './ProtectedRoute';

const requiredTokenPages = [
  '/tickets/:id',
  '/ticket/:id',
  '/booking/:id', // 여기에는 로그인이 필요하지 않은 information도 포함되어 있기 때문에
  // ProtectedRoute에서 걸러준다.
  '/personal-info',
  '/host/register-storage',
  '/host/manage-storage',
  '/host/register-success',
  '/booking-success/:id',
  '/admin',
  '/admin/places',
];
function App() {
  const [cookies] = useCookies(['access_token', 'refresh_token']);
  const [isAuthenticated, setIsAuthenticated] = useRecoilState(authenticatedState);

  const removeFromLocalStorage = () => {
    localStorage.removeItem('storageRegistration');
    localStorage.removeItem('hasPersonalInfo');
    localStorage.removeItem('bookingId');
    localStorage.removeItem('placeId');
    localStorage.removeItem('reservationInfo');
  };
  useEffect(() => {
    if (!cookies.access_token || !cookies.refresh_token) {
      removeFromLocalStorage();
    }
    setIsAuthenticated(!!(cookies.access_token && cookies.refresh_token));
  }, [cookies.access_token, cookies.refresh_token]);

  return (
    <Router>
      <Routes>
        {pages.map((page, index) => {
          const isProtected = requiredTokenPages.includes(page.path);
          const PageComponent = (
            <Layout footer={page.footer} header={page.header}>
              {React.createElement(page.component)}
            </Layout>
          );

          return isProtected && !(cookies.access_token || cookies.refresh_token) ? (
            <Route key={index} path={page.path} element={<ProtectedRoute />}>
              <Route path={page.path} element={PageComponent} />
            </Route>
          ) : (
            <Route key={index} path={page.path} element={PageComponent} />
          );
        })}
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/admin/places" element={<AdminPlacesPage />} />
        <Route path="/host/landing" element={<LandingPage />} />
      </Routes>
    </Router>
  );
}

export default App;
