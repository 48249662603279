import styled from '@emotion/styled';
import CopyToClipboard from 'react-copy-to-clipboard';
import InfoCall from '../../assets/images/InfoCall.png';
import InfoTime from '../../assets/images/InfoTime.png';
import InfoCopy from '../../assets/images/InfoCopy.png';
import InfoLocation from '../../assets/images/InfoLocation.png';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiResponseType } from '../../apis/adminApi';
import { GetBookingDetailType } from '../../types/BookingType';
import {
  cancelBookingApi,
  getBookingByIdApi,
  postCheckInApi,
  postCheckOutApi,
} from '../../apis/bookingApi';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import StatusCompleted from '../../assets/images/StatusCompleted.png';
import StatusWaiting from '../../assets/images/StatusWaiting.png';
import StatusYet from '../../assets/images/StatusYet.png';
import closedDaysFilter, { getTodayStringForShow } from '../../utils/dateFilter';
import TimeToggleUp from '../../assets/images/TimeToggleUp.png';
import TimeToggleDown from '../../assets/images/TimeToggleDown.png';
import { useForm } from 'react-hook-form';
import { formatDateTime } from '../../utils/isoToString';
import { useRecoilState } from 'recoil';
import { authenticatedState } from '../../recoil/authenticatedAtom';
import { authIntentState } from '../../recoil/authIntentAtom';
import ConfirmModal from '../../components/common/ConfirmModal';
import { modalState } from '../../recoil/modalAtom';
import { splitBusinessHour } from '../../utils/parseOperatingTime';
import Spinner from '../../components/common/Spinner';
import { AxiosError } from 'axios';
interface ImageWrapperType {
  imageUrl: string;
}
interface BtnType {
  backgroundColor: string;
  color: string;
}
interface StatusBarColorType {
  lineColor: string;
}
const TicketDetailPage = () => {
  const { t } = useTranslation();
  const [modal, setModal] = useRecoilState(modalState);
  const [isAuthenticated, setIsAuthenticated] = useRecoilState(authenticatedState);
  const [authIntent, setAuthIntent] = useRecoilState(authIntentState);
  const navigate = useNavigate();
  const { reset } = useForm();
  const queryClient = useQueryClient();
  const [timeOpen, setTimeOpen] = useState<boolean>(false);
  const { id } = useParams();
  const {
    data,
    isLoading,
    error,
    refetch,
    isError: isTicketError,
  } = useQuery({
    queryKey: ['booking', id],
    queryFn: async (): Promise<ApiResponseType<GetBookingDetailType>> => {
      return getBookingByIdApi(id!);
    },
    enabled: !!id && !!isAuthenticated,
    retry: 2,
  });
  useEffect(() => {
    if (isTicketError) {
      alert('get Ticket Error, Please contact the administrator');
      navigate('/main');
    }
  }, [isTicketError]);
  const checkInMutation = useMutation<ApiResponseType<{}>, Error, string>({
    mutationFn: (bookingId: string) => postCheckInApi(bookingId),
    onSuccess: (data: ApiResponseType<{}>) => {
      reset();
      queryClient.refetchQueries({ queryKey: ['booking', id] });
      // console.log(data, 'check in success');
    },
    onError: (error) => {
      console.log(error);
      if ((error as AxiosError).response?.status === 403) {
        alert('Access Denied');
      } else alert(t('ticketDetailPage.checkIn_error'));
    },
  });
  const checkoutMutation = useMutation<ApiResponseType<{}>, Error, string>({
    mutationFn: (bookingId: string) => postCheckOutApi(bookingId),
    onSuccess: (data: ApiResponseType<{}>) => {
      reset();
      queryClient.refetchQueries({ queryKey: ['booking', id] });
      console.log(data, 'check out success');
      setModal('modal');
    },
    onError: (error) => {
      console.log(error);
      if ((error as AxiosError).response?.status === 403) {
        alert('Access Denied');
      } else alert(t('unexpected_error'));
    },
  });
  const cancelMutation = useMutation<ApiResponseType<{}>, Error, string>({
    mutationFn: (bookingId: string) => cancelBookingApi(bookingId),
    onSuccess: (data: ApiResponseType<{}>) => {
      reset();
      queryClient.refetchQueries({ queryKey: ['booking', id] });
      console.log(data, 'cancel success');
      navigate('/tickets');
    },
    onError: (error) => {
      console.log(error);
      if ((error as AxiosError).response?.status === 403) {
        alert('Access Denied');
      } else alert(t('unexpected_error'));
    },
  });
  const handleCheckIn = () => {
    if (id) {
      checkInMutation.mutate(id);
    }
  };
  const handleCheckout = () => {
    if (id) {
      checkoutMutation.mutate(id);
    }
  };
  const handleCheckCancel = () => {
    if (id) {
      cancelMutation.mutate(id);
    }
  };
  const handleGoFeedBack = () => {
    window.location.href =
      'https://docs.google.com/forms/d/e/1FAIpQLSd1uh7o9MSt1WG0MtHh9AbWbrk8rSsKh0OJ-1scvTER7NeTBw/viewform';
  };

  if (
    isLoading ||
    checkInMutation.isPending ||
    checkoutMutation.isPending ||
    cancelMutation.isPending
  ) {
    return <Spinner />;
  }

  if (!data) {
    return null;
  }
  // tip : 구조분해할당에서 데이터가 없을 경우를 대비하기 위해서는 || '' 이 아닌 || {} 를 쓴다!!!!
  const { place, booking } = data?.data || {};
  const { paymentAmount, pickUpAt, numOfBags, dropOffAt, status } = booking || {};
  const { pictures, name, contact, businessHour, addr, roadAddr } = place || {};
  const { isOpen24Hours } = closedDaysFilter(businessHour);
  const todayBusinessHour = businessHour.find(
    (it) => it.dayOfWeek === getTodayStringForShow(businessHour),
  )?.businessHour;
  return (
    <Container>
      {status === 'WAIT' && (
        <ConfirmModal
          validateForms={handleCheckCancel}
          bigText={t('ticketPage.cancel_are_you_sure')}
          smallTextFirst={t('ticketPage.confirm_cancel')}
          smallTextSecond={t('ticketPage.confirm_cancel_2')}
          cancelText={'NO'}
          confirmText={'YES'}
        />
      )}
      {status === 'COMPLETED' && (
        <ConfirmModal
          validateForms={handleGoFeedBack}
          bigText={t('ticketPage.feedback_are_you_sure')}
          smallTextFirst={t('ticketPage.confirm_feedback')}
          smallTextSecond={t('ticketPage.confirm_feedback_2')}
          cancelText={'SKIP'}
          confirmText={'GO'}
        />
      )}
      <ImageWrapper imageUrl={(pictures && pictures[0]) || ''}>
        <ImageOverlay>
          <StoreTitle>{name}</StoreTitle>
        </ImageOverlay>
      </ImageWrapper>
      {/* reservation */}
      <ReservationWrapper style={{ paddingTop: 31 }}>
        <div style={{ marginRight: 39 }}>
          <GreyText>Drop off</GreyText>
          <BlackText>{formatDateTime(dropOffAt)}</BlackText>
        </div>
        <div>
          <GreyText>Bags</GreyText>
          <BlackText>{numOfBags}</BlackText>
        </div>
      </ReservationWrapper>
      <ReservationWrapper>
        <div style={{ marginRight: 39 }}>
          <GreyText>Pick up</GreyText>
          <BlackText>{formatDateTime(pickUpAt)}</BlackText>
        </div>
        <div>
          <GreyText>Price</GreyText>
          <BlackText>
            <Trans i18nKey="paymentAmount">{{ paymentAmount }} won</Trans>
          </BlackText>
        </div>
      </ReservationWrapper>
      {/* place info */}
      <DetailInfoWrapper>
        <DetailInfo>
          <DetailIcon src={InfoLocation} />
          <div style={{ fontSize: '0.9375rem', fontFamily: 'DM Sans Bold' }}>{addr}</div>
          <CopyToClipboard text={addr || ''} onCopy={() => alert('주소가 복사되었습니다')}>
            <img src={InfoCopy} style={{ width: '1.5rem', height: '1.5rem' }} />
          </CopyToClipboard>
        </DetailInfo>
        <DetailInfo>
          <DetailIcon src={InfoCall} />
          <a style={{ color: 'black' }} href={`tel:${contact}`}>
            {data?.data.place.contact}
          </a>
        </DetailInfo>
        <DetailInfo>
          <DetailIcon src={InfoTime} />
          {/* <div>{data?.data.place.businessHour}</div> */}
          <div>
            {isOpen24Hours()
              ? t('operateDate.day_24')
              : splitBusinessHour(todayBusinessHour)?.endTime === '23:59'
              ? `${splitBusinessHour(todayBusinessHour)?.startTime} ~ 24:00`
              : todayBusinessHour || t('CLOSE')}
          </div>
          <ToggleDiv onClick={() => setTimeOpen(!timeOpen)}>
            <ToggleImg src={timeOpen ? TimeToggleUp : TimeToggleDown} />
          </ToggleDiv>
        </DetailInfo>
        {timeOpen &&
          businessHour?.map((it, index) =>
            it.dayOfWeek === getTodayStringForShow(businessHour) ? (
              <BusinessHourWrapper
                style={{ color: '#0094FF', fontFamily: 'DM Sans Bold' }}
                key={index}
              >
                <div>{it.dayOfWeek}</div>
                <div>
                  {it.closed
                    ? t('CLOSE')
                    : splitBusinessHour(it.businessHour)?.endTime === '23:59'
                    ? `${splitBusinessHour(it.businessHour)?.startTime} ~ 24:00`
                    : it.businessHour}
                </div>
              </BusinessHourWrapper>
            ) : (
              <BusinessHourWrapper key={index}>
                <div>{it.dayOfWeek}</div>
                <div>
                  {' '}
                  {it.closed
                    ? t('CLOSE')
                    : splitBusinessHour(it.businessHour)?.endTime === '23:59'
                    ? `${splitBusinessHour(it.businessHour)?.startTime} ~ 24:00`
                    : it.businessHour}
                </div>
              </BusinessHourWrapper>
            ),
          )}
      </DetailInfoWrapper>
      <StatusWrapper>
        <div style={{ fontSize: '1.0625rem', fontFamily: 'DM Sans Bold' }}>Status</div>
        <StatusBar>
          {/* <div> */}
          <div style={{ display: 'flex', width: '100%' }}>
            <ImageContainer lineColor="#0094ff">
              <img src={StatusCompleted} />
              {/* <StatusMessage>Before Drop-off</StatusMessage> */}
            </ImageContainer>
            <StatusMessage>{t('ticketPage.before_drop_off')}</StatusMessage>
          </div>
          <div style={{ display: 'flex', width: '100%' }}>
            <ImageContainer
              lineColor={
                status === 'WAIT' ? '#C6C6C6' : status === 'IN_PROGRESS' ? '#0094ff' : '#C6C6C6'
              }
            >
              <img
                src={
                  status === 'WAIT'
                    ? StatusWaiting
                    : status === 'IN_PROGRESS'
                    ? StatusCompleted
                    : StatusCompleted
                }
              />
            </ImageContainer>
            <StatusMessage>{t('ticketPage.enjoying_adventure')}</StatusMessage>
          </div>
          {/* <ImageContainer lineColor="#0094ff"> */}
          <div style={{ display: 'flex', width: '100%' }}>
            <StatusIcon
              src={
                status === 'WAIT'
                  ? StatusYet
                  : status === 'IN_PROGRESS'
                  ? StatusWaiting
                  : StatusCompleted
              }
            />
            <StatusMessage>{t('ticketPage.pick_up_completed')}</StatusMessage>
          </div>
        </StatusBar>
      </StatusWrapper>

      {status === 'WAIT' && <CancelBtn onClick={() => setModal('modal')}>CANCEL</CancelBtn>}
      {status !== 'COMPLETED' && (
        <Btn
          backgroundColor={
            status === 'WAIT' ? '#d9d9d9' : status === 'IN_PROGRESS' ? '#0094ff' : ''
          }
          color={status === 'WAIT' ? '#675e5e' : status === 'IN_PROGRESS' ? '#1B303F' : ''}
        >
          {status === 'WAIT' && <div onClick={handleCheckIn}>DROP-OFF</div>}
          {status === 'IN_PROGRESS' && <div onClick={handleCheckout}>PICK-UP</div>}
        </Btn>
      )}
    </Container>
  );
};
const CancelBtn = styled.div`
  text-decoration: underline;
  border-top: 2px solid #e2e2e2;
  padding: 40px 0;
  margin: 0 20px;
  font-size: 0.9375rem;
  font-family: 'DM Sans Medium';
  color: #828282;
  width: calc(100% - 40px);
  text-align: center;
  cursor: pointer;
  @media only screen and (max-width: 700px) {
    padding: 40px 0 110px 0;
  }
`;
const BusinessHourWrapper = styled.div`
  padding: 0 30px 18px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #bebebe;
  font-size: 0.875rem;
  font-family: 'DM Sans Regular';
`;
const ToggleDiv = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;
const ToggleImg = styled.img`
  width: 1.5rem;
  height: auto;
`;
const ImageContainer = styled.div<StatusBarColorType>`
  position: relative;
  width: 1.5rem;
  /* height: auto; */
  margin-bottom: 1.7rem;
  margin-right: 35px;
  img {
    display: block;
    width: 1.5rem;
    height: auto;
  }

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    width: 2px;
    height: 1.7rem;
    background-color: ${(props) => props.lineColor || '#C6C6C6'}; /* 선의 색상을 동적으로 변경 */
    transform: translateX(-50%);
  }
`;

const StatusIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 35px;
`;
const StatusMessage = styled.div`
  color: #1b303f;
  font-size: 1.125rem;
  font-family: 'DM Sans ExtraBold';
`;
const Btn = styled.div<BtnType>`
  /* position: fixed;
  bottom: 0; */
  width: 100%;
  padding: 13px 19px 16px 0;
  /* height: 70px; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: 'DM Sans ExtraBold';
  font-size: 1.875rem;

  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};

  cursor: pointer;
  @media only screen and (max-width: 700px) {
    position: fixed;
    bottom: 70px;
    left: 0;
    right: 0;
  }
`;

const StatusWrapper = styled.div`
  box-sizing: border-box;
  width: calc(100% - 40px);
  margin: 0 20px 40px 20px;
  padding: 30px 10px 0 10px;
  border-top: 2px solid #e2e2e2;
`;

const StatusBar = styled.div`
  padding-left: 22px;
  padding-top: 20px;
  /* display: flex; */
`;
const ImageWrapper = styled.div<ImageWrapperType>`
  background-image: url(${(props) => props.imageUrl});
  width: 100%;
  height: 123px;
  background-position: center;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 100%;
  /* background-size: ; */

  position: relative;
  flex: 0 0 auto; // 추가된 부분. 부모요소가 flex-box일 때 높이가 지정한대로 되지 않는 문제를 해결해줌.
`;

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(56, 55, 55, 0.5);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StoreTitle = styled.div`
  font-size: 1.5rem;
  color: #ffffff;
  font-family: 'DM Sans Bold';
`;

const ReservationWrapper = styled.div`
  display: flex;
  padding-top: 12px;
  margin-left: 48px;
  width: '100%';
  align-self: flex-start;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 140px);
  width: 100%;
  overflow: scroll;
  /* Chrome, Safari */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Firefox */
  scrollbar-width: none;

  /* Internet Explorer */
  -ms-overflow-style: none;
`;

const DetailInfoWrapper = styled.div`
  margin: 35px 20px 0 20px;
`;
const DetailInfo = styled.div`
  border-top: 2px solid #e2e2e2;
  font-size: 0.9375rem;
  font-family: 'DM Sans Regular';
  padding: 12px 6px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;

  *:not(:last-child) {
    margin-right: 14px;
  }
`;

const DetailIcon = styled.img`
  width: 1.875rem;
  height: 1.875rem;
`;

const GreyText = styled.div`
  color: #675e5e;
  font-size: 0.875rem;
  font-family: 'DM Sans Regular';
  margin-bottom: 2px;
`;
const BlackText = styled.div`
  color: #000000;
  font-size: 0.9375rem;
  font-family: 'DM Sans Bold';
  white-space: 0.5px;
`;

const TitleLine = styled.div`
  width: 100%;
  height: 0;
  position: relative;
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -55px;
  background-color: #ffffff;
  border: 2px solid #000000;
  margin: 0 20px;
  padding: 18px 30px;
  border-radius: 6px;
  background-color: #fcfafa;
`;
export default TicketDetailPage;
